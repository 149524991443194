import React, { FC } from 'react';
import moment from 'moment';

import { IReportStayInAreas } from '../../../models/types';

import Table from '../../../components_/Table';
import TableColText from '../../../components_/Table/TableColText';

interface IProps {
  list: IReportStayInAreas[];
}

const List: FC<IProps> = ({ list }) => {
  const rows = list.map(item => ({
    items: {
      employeeName: <TableColText>{item.employeeName}</TableColText>,
      areaName: <TableColText>{item.areaName}</TableColText>,
      mapName: <TableColText>{item.mapName}</TableColText>,
      officeName: <TableColText>{item.officeName}</TableColText>,
      // restrictions: <TableColText>{item.restrictions}</TableColText>,
      enterTime: (
        <TableColText>
          {item.enterTime && moment(item.enterTime).format('DD.MM.YYYY HH:mm:ss')}
        </TableColText>
      ),
      leaveTime: (
        <TableColText>
          {item.leaveTime && moment(item.leaveTime).format('DD.MM.YYYY HH:mm:ss')}
        </TableColText>
      ),
      duration: <TableColText>{item.duration}</TableColText>
    }
  }));

  const cols = [
    {
      title: 'ФИО сотрудника',
      field: 'employeeName'
    },
    {
      title: 'Название зоны',
      field: 'areaName'
    },
    {
      title: 'План',
      field: 'mapName'
    },
    {
      title: 'Место',
      field: 'officeName'
    },
    // {
    //   title: 'Ограничения зоны',
    //   field: 'restrictions'
    // },
    {
      title: 'Дата/Время входа',
      field: 'enterTime'
    },
    {
      title: 'Дата/Время выхода',
      field: 'leaveTime'
    },
    {
      title: 'Время пребывания в зоне',
      field: 'duration'
    }
  ];

  return <Table cols={cols} rows={rows} />;
};

export default List;
