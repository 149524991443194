import React, {ChangeEvent, FC, ReactNode} from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { MtsButton, MtsCard } from '@mts-ds/components-react';
import { InputSizes } from '@mts-ds/components/dist/types/components/input/enums/sizes.enum';
import { ButtonSizes, ButtonColors } from '@mts-ds/components/dist/types/components/button/enums';

import { IProps as IFormProps } from './index';
import useRequestHandler from '../../../../../hooks/useRequestHandler';
import {size, required} from '../../../../../utils/validation';

import Typography from '../../../../../components_/Typography';
import FormBase from '../../../../../components_/Form';
import FormRow from '../../../../../components_/Form/FormRow';
import FormCol from '../../../../../components_/Form/FormCol';
import FormInput from '../../../../../components_/Form/FormInput';
import DrawerStickyActions from '../../../../../components_/Drawer/DrawerStickyActions';
import { IUpdateMapBody } from '../../../../../services/MapsService';
import FormFileInput from '../../../../../components_/Form/FormFileInput';
import {EditPlanMode, IId} from '../../../../../models/types';
import FormCoordinates from "./FormCoordinates";

import {useRouteMatch} from "react-router-dom";
import {paths} from "../../../../Root/paths";


const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginTop: '12px',
    width: '100%'
  },
  card: {
    margin: spacing(3, 0)
  },
  cardBody: {
    display: 'flex',
    padding: spacing(2),
    '& > *:not(:last-child)': {
      marginRight: spacing(1)
    }
  },
  icon: {
    fontSize: 36
  }
}));

type IValues = IUpdateMapBody;

type IRender = (props: FormRenderProps<IValues>) => ReactNode;

interface IProps {
  // data: IFormProps['data'];
  item: any;
  office: any;
  id: IId;
  coordinates: IFormProps['coordinates'];
  updateOrCreate: any;
  setMap: any;
  setEditPlanMode: any;
  onCancel: any;
  onSuccess: any;
  onClickRemove: any;
  setCoordinates:any;
}
const Form: FC<IProps> = ({
  // data,
  item,
  office,
  coordinates,
  updateOrCreate,
  setMap,
  setEditPlanMode,
  onCancel,
  onSuccess,
  onClickRemove,
  setCoordinates
}) => {

  const classes = useStyles();
  const requestHandler = useRequestHandler();
  // tslint:disable-next-line:prettier
  const isCreate = useRouteMatch(paths.map.office.plan.create)?.isExact;
  const isEdit = useRouteMatch(paths.map.office.plan.edit())?.isExact;
  const defaultValues: IValues = {
    id: '00000000-0000-0000-0000-000000000000',
    contentId: null,
    name: '',
    officeId: '',
    descr: null,
    bbox: null,
    gbox: null,
    heightGround: null,
    file: null
  };


  const initialValues: IValues = {
    ...defaultValues,
    ...(isEdit && item)
  };

  const onSubmit = async (values: IValues) => {

    const body = {
      id: values.id,
      contentId: values.contentId,
      name: values.name,
      officeId: office.id,
      descr: values.descr,
      gbox: values.position ? values.position: values.gbox,
      heightGround: values.heightGround? values.heightGround : 0,
      file: values.file
    };

    const error = await updateOrCreate(body);

    requestHandler({
      error,
      entity: 'plan',
      onSuccess,
      isCreate,
      isEdit
    });
  };
  const handleEdit = (mode: EditPlanMode) => () => {
    setEditPlanMode(mode);
  };

  const onLoadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = event => {
        if (event.target) {
          // console.log(event.target);
          if (typeof event.target.result === "string") {
            const previewArray = event.target.result.split(/[:,;,,]/ )
            const img = new Image();
            img.src = event.target.result;
            // tslint:disable-next-line:only-arrow-functions
            img.onload = function () {
              const prewCoords = [
                office.location.lon,
                (office.location.lat - img.height / 100 * 0.000008998227349212),
                (office.location.lon + img.width / 100 * (1 / (111320 * Math.cos(office.location.lat / 180 * Math.PI)))),
                (office.location.lat - img.height / 100 * 0.000008998227349212),
                office.location.lon,
                office.location.lat,
                (office.location.lon + img.width / 100 * (1 / (111320 * Math.cos(office.location.lat / 180 * Math.PI)))),
                office.location.lat,
              ]
              setMap({
                ...item,
                gbox:prewCoords,
                previewFormat:previewArray[1],
                preview: previewArray[3],
                file
                })
              setCoordinates(prewCoords);
            }

          }
        }
      };

      reader.readAsDataURL(file);

    }

  }

  const render: IRender = ({ handleSubmit }) => {

    return (
      <FormBase onSubmit={handleSubmit} onFocus={handleEdit(EditPlanMode.Lock)}>
        <div id='map'/>
        <Typography variant="h3Bold">
          {(isEdit && 'Редактировать план') || 'Добавить новый план'}
        </Typography>
        <MtsCard className={classes.card}>
          {/*<MtsCardBody className={classes.cardBody}>*/}
          {/*  <span style={{ width: '36px', height: '36px' }}>*/}
          {/*    <Icon name="markerDrag" className={classes.icon} />*/}
          {/*  </span>*/}
          {/*  <Typography variant="p3Regular">*/}
          {/*    Установите пин вручную на нужной точке или введите адрес в поле*/}
          {/*  </Typography>*/}
          {/*</MtsCardBody>*/}
        </MtsCard>
          <FormRow>
            <FormCol>
              <FormInput
                name="name"
                label="Название плана"
                placeholder="Введите название плана"
                size={'s' as InputSizes}
                description="Допустимое количество символов: 40"
                useFieldConfig={{ validate: value => required(value) || size(value, 40) }}
                // disabled={isEdit}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormInput
                name="heightGround"
                label="Высота над уровнем земли (м)"
                placeholder="Введите высоту"
                size={'s' as InputSizes}
                type='number'
                // useFieldConfig={{ validate: required }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <FormFileInput name="file" label="План" placeholder="Загрузите план" onLoad={onLoadImage}/>
            </FormCol>
          </FormRow>
        <FormCoordinates coordinates={coordinates} />
        {isEdit && (
          <MtsButton
            size={'s' as ButtonSizes}
            color={'secondary' as ButtonColors}
            onClick={onClickRemove}
            className={classes.button}
          >
            Удалить план
          </MtsButton>
        )}
        <DrawerStickyActions cancel="Отмена" confirm="Сохранить" onClickCancel={onCancel} />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={render} />;
};

export default Form;
