import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { iRootState } from '../../../store';
import { paths } from '../../Root/paths';

import AreaListItem from './AreaListItem';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  layers: state.infrastructureMap.layers
});

type connectedProps = ReturnType<typeof mapState>;

type IProps = connectedProps;

interface IAreaParams {
  id: string;
}

const AreaMarkerList: FC<IProps> = ({ map, layers }) => {
  const { areas } = map;
  const { isAreas } = layers;

  const areaEditMatch = useRouteMatch<IAreaParams>(paths.map.office.areas.edit());

  const areaParamId = areaEditMatch ? areaEditMatch.params.id : null;
  const areaList = areaParamId ? areas.filter(item => item.id !== areaParamId) : areas;

  return <>{isAreas && areaList.map(area => <AreaListItem key={area.id} area={area} />)}</>;
};

const withConnect = connect(mapState);

export default withConnect(AreaMarkerList);
