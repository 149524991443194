import React, { FC, useState, useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useMtsModal } from '@mts-ds/components-react';
import useRequestHandler from '../../../../../../hooks/useRequestHandler';

import { Dispatch, iRootState } from '../../../../../../store';
import { IId, IUser } from '../../../../../../models/types';
import { getTimeFromMinutes, getMinutesFromTime } from '../../../../../../utils/convertTime';
import { paths } from '../../../../../Root/paths';
import { mapColor } from '../../../../../../theme';

import FormBase from '../../../../../../components_/Form';
import Modal from '../../../../../../components_/Modal';
import DrawerContentItemArea from '../../../../../../components_/Drawer/DrawerContentItemArea';

import FormDraw from './FormDraw';
import FormInfo from './FormInfo';
import FormAccess from './FormAccess';
import FormDelete from './FormDelete';
import FormActions from './FormActions';
import FormCoordinates from './FormCoordinates';

const mapState = (state: iRootState) => ({
  data: state.infrastructureAreas.data,
  map: state.infrastructureMap.map,
  area: state.infrastructureAreas.item,
  type: state.infrastructureMap.type,
  coordinates: state.infrastructureAreas.coordinates,
  isGeo: state.infrastructureMap.layers.isGeo
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getDataUsers: d.infrastructureAreas.getDataUsers,
    resetDataUsers: d.infrastructureAreas.resetDataUsers,
    deleteArea: d.infrastructureAreas.deleteArea,
    updateOrCreateArea: d.infrastructureAreas.updateOrCreateArea,
    setBoxCreateMode: d.infrastructureMap.setBoxCreateMode,
    setCircleCreateMode: d.infrastructureMap.setCircleCreateMode,
    setPolygonCreateMode: d.infrastructureMap.setPolygonCreateMode,
    resetFocus: d.infrastructureMap.resetFocus
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

interface IFormValues {
  // id: IId;
  name: string;
  descr: string;
  dangerClassId: IId | null;
  mapColor: string;
  workStart: string;
  workEnd: string;
  allowedHours: number;
  allowedMinutes: number;
  protectionsList: IId[];
  denyFrom: IId[];
  employees: IUser[];
  employeesList: IId[];
  position: number[] | null;
  geomCoords: number[] | null;
}

interface IParams {
  officeId: string;
}

type IRenderForm = (props: FormRenderProps<IFormValues>) => ReactNode;

const defaultValues: IFormValues = {
  // id: 0,
  name: '',
  descr: '',
  dangerClassId: -1,
  mapColor: mapColor.greySteel,
  workStart: '',
  workEnd: '',
  allowedHours: 0,
  allowedMinutes: 0,
  protectionsList: [],
  denyFrom: [],
  employees: [],
  employeesList: [],
  position: null,
  geomCoords: null
};

const Form: FC<IProps> = ({
  data,
  map,
  area,
  type,
  coordinates,
  getDataUsers,
  resetDataUsers,
  deleteArea,
  updateOrCreateArea,
  setBoxCreateMode,
  setCircleCreateMode,
  setPolygonCreateMode,
  resetFocus,
  isGeo
}) => {
  const { id: currentMapId } = map;

  const { officeId } = useParams<IParams>();

  const history = useHistory();
  const requestHandler = useRequestHandler();
  const [initialValues, setInitialValues] = useState(defaultValues);

  const [openDeleteModal] = useMtsModal(Modal, {
    header: 'Удалить зону',
    text: 'Вы действительно хотите удалить зону?',
    buttonTextConfirm: 'Удалить',
    ...(area && {
      onMtsModalConfirm: async () => {
        const error = await deleteArea(area.id);

        requestHandler({
          error,
          entity: 'area',
          onSuccess: () => {
            history.push(paths.map.office.areas.root);
          },
          isDelete: true
        });
      }
    })
  });

  const isCreate = !Boolean(area);
  const isEdit = Boolean(area);

  useEffect(() => {
    if (area) {
      const { workDurationMinutes, geomGlobalCoords, geomCoords } = area;
      const position = isGeo ? geomGlobalCoords : geomCoords;

      const [allowedHours, allowedMinutes] = getTimeFromMinutes(workDurationMinutes);

      setInitialValues({
        ...area,
        position,
        allowedHours,
        allowedMinutes
      });
    }
  }, [area]);

  const goBack = () => {
    resetFocus();
    history.goBack();
  };

  const onSubmit = async (values: IFormValues) => {
    // tslint:disable-next-line: no-shadowed-variable
    const { allowedHours, allowedMinutes, employees, position, ...data } = values;

    // tslint:disable-next-line: no-shadowed-variable
    const workDurationMinutes = getMinutesFromTime(allowedHours, allowedMinutes) || null;
    const mapId = area ? area.mapId : currentMapId;
    const employeesList = employees.map(({ id }) => id);

    const body = {
      ...data,
      geomGlobalCoords: isGeo ? position : null,
      geomCoords: isGeo ? null : position,
      workDurationMinutes,
      mapId,
      geomType: type,
      employeesList
    };

    const error = await updateOrCreateArea(body);

    requestHandler({
      error,
      entity: 'area',
      onSuccess: goBack,
      isCreate,
      isEdit
    });
  };

  const renderForm: IRenderForm = ({ handleSubmit, values: { mapColor: color } }) => {
    return (
      <FormBase onSubmit={handleSubmit}>
        <DrawerContentItemArea area={area} color={color} variant="form" />
        <FormDraw
          area={area}
          type={type}
          setBoxCreateMode={setBoxCreateMode}
          setCircleCreateMode={setCircleCreateMode}
          setPolygonCreateMode={setPolygonCreateMode}
        />
        <FormInfo data={data} />
        <FormAccess getDataUsers={getDataUsers} resetDataUsers={resetDataUsers} data={data} />
        {area && <FormDelete onOpenDeleteModal={openDeleteModal} />}
        <FormActions isCreate={Boolean(area)} onGoBack={goBack} />
        <FormCoordinates coordinates={coordinates} />
      </FormBase>
    );
  };

  return <FinalForm initialValues={initialValues} onSubmit={onSubmit} render={renderForm} />;
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Form);
