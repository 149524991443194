/**
 * Модель для работы с картой
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import MapsService, { IUpdateMapBody } from '../services/MapsService';
import {
  IMap,
  ICoordXY,
  ICoordXYXY,
  AreaTypes,
  AreaModes,
  IId,
  EditPlanMode,
  ICoordXYZW
} from './types';
import { iRootState, Dispatch } from '../store';

interface IControl {
  zoom: number;
  center: ICoordXY;
  bounds: ICoordXYXY;
}

interface ILayers {
  isGeo: boolean;
  isUsers: boolean;
  isAreas: boolean;
  isInfrastructure: boolean;
  isHeatMap: boolean;
  isCameras: boolean;
}

interface IState {
  map: IMap;
  control: IControl;
  layers: ILayers;
  mode: AreaModes;
  type: AreaTypes;
  editPlan: boolean;
  editPlanMode: EditPlanMode;
  coordinates: any;
  planImg: string;
}

// const calcControl = ([q, w, e, r, t, y, u, i]: number[]) => ({
//   zoom: 19.5,
//   center: [q + u - 0.0001, w + i - 0.0001].map(coord => coord / 2),
//   bounds: []
// });

const initialState: IState = {
  control: {
    zoom: 0,
    center: [0, 0],
    bounds: [[0, 0], [0, 0]]
  },
  map: {
    id: '',
    contentId: '',
    name: '',
    preview: '',
    previewFormat: '',
    bbox: [0, 0, 0, 0],
    gbox: [0, 0, 0, 0, 0, 0, 0, 0],
    areas: [],
    beacons: [],
    cameras: [],
    file: null
  },
  layers: {
    isGeo: true,
    isUsers: true,
    isAreas: true,
    isInfrastructure: false,
    isHeatMap: false,
    isCameras: false
  },
  mode: AreaModes.Default,
  type: AreaTypes.Marker,
  editPlan: false,
  editPlanMode: EditPlanMode.Lock,
  coordinates: null,
  planImg: ''
};

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setMap(state: IState, payload: IMap): IState {
      return { ...state, map: payload };
    },
    setFocus(state: IState, payload): IState {
      return {
        ...state,
        control: {
          // ...state.control,
          ...payload
        }
      };
    },
    setControl(state: IState, payload: IControl): IState {
      return {
        ...state,
        control: payload
      };
    },
    setLayers(state: IState, payload: ILayers): IState {
      return {
        ...state,
        layers: {
          ...state.layers,
          ...payload
        }
      };
    },
    setEditPlan(state: IState, payload: boolean): IState {
      return {
        ...state,
        editPlan: payload
      };
    },
    setPlanImg(state: IState, payload: string): IState {
      return {
        ...state,
        planImg: payload
      };
    },
    resetPlanImg(state: IState): IState {
      return {
        ...state,
        planImg: ''
      };
    },
    setEditPlanMode(state: IState, payload: EditPlanMode): IState {
      return {
        ...state,
        editPlanMode: payload
      };
    },
    setCoordinates(state: IState, payload: ICoordXYZW): IState {
      return { ...state, coordinates: payload };
    },
    resetCoordinates(state: IState): IState {
      const { coordinates } = initialState;

      return { ...state, coordinates };
    },
    setDefaultMode(state: IState): IState {
      return { ...state, mode: AreaModes.Default };
    },
    setBoxEditMode(state: IState): IState {
      return { ...state, mode: AreaModes.Edit, type: AreaTypes.Box };
    },
    setCircleEditMode(state: IState): IState {
      return { ...state, mode: AreaModes.Edit, type: AreaTypes.Circle };
    },
    setPolygonEditMode(state: IState): IState {
      return { ...state, mode: AreaModes.Edit, type: AreaTypes.Polygon };
    },
    setMarkerEditMode(state: IState): IState {
      return { ...state, mode: AreaModes.Edit, type: AreaTypes.Marker };
    },
    setBoxCreateMode(state: IState): IState {
      return { ...state, mode: AreaModes.Create, type: AreaTypes.Box };
    },
    setCircleCreateMode(state: IState): IState {
      return { ...state, mode: AreaModes.Create, type: AreaTypes.Circle };
    },
    setPolygonCreateMode(state: IState): IState {
      return { ...state, mode: AreaModes.Create, type: AreaTypes.Polygon };
    },
    setMarkerCreateMode(state: IState): IState {
      return { ...state, mode: AreaModes.Create, type: AreaTypes.Marker };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      resetFocus(payload, rootState: iRootState) {
        // const { bbox, gbox } = rootState.infrastructureMap.map;
        // const { isGeo } = rootState.infrastructureMap.layers;
        // const focus = calcControl(gbox);
        // const { center } = isGeo ? calcGeoControl(gbox) : calcControl(bbox);
        // this.setFocus({ center });
      },
      async getMap(id, rootState: iRootState) {
        const currentMapId = rootState.infrastructureMap.map.id;
        const defaultCurrentOfficeId = { id: null };
        const { id: currentOfficeId } =
          rootState.infrastructureOffices.item || defaultCurrentOfficeId;
        const saveMapId = localStorage.getItem('mapId');

        let mapId = id || saveMapId;

        // Если нет ни одной карты, то выбираем по умолчанию
        if (!mapId && currentOfficeId) {
          const { data: maps } = await MapsService.getAll({ officeId: currentOfficeId });

          // MOCK
          const mapsReverse = maps.reverse();

          mapId = mapsReverse[0] && mapsReverse[0].id;
        }

        // tslint:disable-next-line: triple-equals
        if (!mapId) {
          return;
        }
        const { data } = await MapsService.get(mapId);

        // Если меняем карту
        if (mapId !== currentMapId) {
          // то сбрасываем пользователей
          d.infrastructureUsers.resetUsers();

          // то фиксируем карту
          localStorage.setItem('mapId', data.id);
        }

        // Если место отсутствует, то устанавливаем
        if (!rootState.infrastructureOffices.item) {
          d.infrastructureOffices.setOffice(data.place);
        }

        // Перефокусируем
        const { bbox, gbox } = data;

        /* gbox может быть undefined, тогда не выполянем фокусировку на объекте,
        иначе появляются ошибки
        нужно уточнить, будет ли в дальнейшем поле gbox всегда корректным,
        тогда можно будет убрать эту проверку */
        if (gbox) {
          // const control = isGeo ? calcGeoControl(gbox) : calcControl(bbox);

          // this.setControl(control);
          window.history.pushState(null, '', '?mapId=' + mapId);
          this.setMap(data);
        }
      },
      async updateOrCreate(body: IUpdateMapBody) {
        const image = new FormData();
        if (body.file) {
          image.append('file', body.file);
        }
        try {
          if (body.file) {
            const { data } = await MapsService.updateOrCreateMapImage(image);
            await MapsService.updateOrCreateMap({ ...body, contentId: data });
          } else {
            await MapsService.updateOrCreateMap(body);
          }
        } catch (error) {
          return error;
        }
      },
      async getPlanImg(id: IId) {
        try {
          const data = await MapsService.getMapImage(id);
          this.setPlanImg(data);
        } catch (error) {
          return error;
        }
      },

      async remove(id: IId) {
        try {
          await MapsService.deleteMap(id);
        } catch (error) {
          return error;
        }
      },

      resetMap() {
        this.setMap(initialState.map);
      },

      async editMapPosition(file) {
        // console.log(file);
        // const file = e.target.files && e.target.files[0];
        if (file) {
          const reader = new FileReader();

          reader.onload = event => {
            if (event.target) {
              // console.log(file.size);
              // console.log(event.target.result as string);
            }
          };

          reader.readAsDataURL(file);
        }
      },

      showUsers(isUsers) {
        this.setLayers({ isUsers });
      },
      showAreas(isAreas) {
        this.setLayers({ isAreas });
      },
      showInfrastructure(isInfrastructure) {
        this.setLayers({ isInfrastructure });
      },
      showHeatMap(isHeatMap) {
        this.setLayers({ isHeatMap });
      },
      showCameras(isCameras) {
        this.setLayers({ isCameras });
      }
    };
  }
};

export const infrastructureMap: typeof model = createModel(model);
