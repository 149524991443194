import React, { FC } from 'react';
import { CheckboxProps } from 'antd/es/checkbox';

import { Checkbox as AntCheckbox } from 'antd';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';
interface IInputProps {
  name: string;
  valid?: boolean;
  onChange?: any;
  value?: any;
}

export type IProps = IFormGroupTextProps & IInputProps & CheckboxProps;

// const handleOnChange: CheckboxProps['onChange'] = (e) => {
//
//   console.log(`checked = ${e.target.checked}`, e);
//
//   const { checked } = e.target;
//   e.target.value = checked;
//   // onChange(e);
// };

const handleMtsChange = (onChange: any) => (event: any) => {
  const { checked } = event.target;
  event.target.value = checked;
  onChange(event);
};

const Checkbox: FC<IProps> = ({ label, description, valid, onChange, ...props }) => {
  return (
    <FormGroup label={label} description={description} valid={valid}>
      <AntCheckbox {...(onChange && { onChange: handleMtsChange(onChange) })} {...props} />
    </FormGroup>
  );
};

export default Checkbox;
