import React, { FC, useEffect } from 'react';
import { MtsButton } from '@mts-ds/components-react';
import { ButtonColors, ButtonFormats } from '@mts-ds/components/dist/types/components/button/enums';

import checkFilter from '../../../utils/checkFilter';
import { IProps as IStayInAreasProps } from '../StayInAreas';

import ContentFilter from '../../../components_/Content/ContentFilter';
import FormRow from '../../../components_/Form/FormRow';
import FormCol from '../../../components_/Form/FormCol';
import Dropdown from '../../../components_/Fields/Dropdown';
import Icon from '../../../components_/Icon';
import Tooltip from '../../../components_/Tooltip';
import Button from '../../../components_/Fields/Button';
import InputWithDate from '../../../components_/Fields/DatePicker/inputWithDate/inputWithDate';
import AntSelect from '../../../components_/Fields/AntSelect';
import AntCheckbox from '../../../components_/Fields/AntCheckbox';

interface IProps {
  data: IStayInAreasProps['data'];
  filter: any;
  isLoadingGetList: boolean;
  isLoadingExportList: boolean;
  list: IStayInAreasProps['list']['data'];
  onChange: any;
  onClickApply: () => void;
  onClickExport: () => void;
  onClickReset: () => void;
  getDataAreas: IStayInAreasProps['getDataAreas'];
  resetDataAreas: IStayInAreasProps['resetDataAreas'];
}

const Filter: FC<IProps> = ({
  data,
  filter,
  isLoadingGetList,
  isLoadingExportList,
  list,
  onChange,
  onClickApply,
  onClickExport,
  onClickReset,
  getDataAreas,
  resetDataAreas
}) => {
  const { offices, brigades, areas } = data;
  const { mapId, areaId, listOfEmployees, isCollapseTransitions, from, to } = filter;

  const { isApply, isDownload, isShowReset } = checkFilter(filter, ['from', 'to'], list);

  useEffect(() => {
    if (mapId) {
      getDataAreas(mapId);

      return () => {
        resetDataAreas();
        onChange({
          target: {
            name: 'areaId',
            value: ''
          }
        });
      };
    }
  }, [mapId]);

  const officeOptions = offices.map(({ name, plans }) => ({ [name]: plans }));
  // const brigadeOptions = brigades.map(({ name, employees }) => ({
  //   [name]: employees && employees.map(({ userId, fio }) => ({ id: userId, name: fio }))
  // }));
  const brigadeOptions = brigades.map(group => {
    return {
      key: group.id,
      label: group.name,
      title: group.name,
      options: group.employees.map(employee => {
        return { label: employee.fio, value: employee.userId };
      })
    };
  });
  const areasOptions = areas.map(({ id, name }) => ({ id, name }));

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <AntSelect
            name="listOfEmployees"
            size="large"
            label="Сотрудник/Подразделение (необязательно)"
            placeholder="Выберите значение"
            options={brigadeOptions}
            optionFilterProp="label"
            mode="multiple"
            allowClear
            value={listOfEmployees}
            onChange={onChange}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="mapId"
            label="Место/План (необязательно)"
            placeholder="Выберите значение"
            groupOptions={officeOptions}
            value={mapId}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol>
          <Dropdown
            name="areaId"
            label="Зона (необязательно)"
            placeholder="Выберите значение"
            options={areasOptions}
            value={areaId}
            disabled={!mapId}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="from" label="Дата от" type="date" value={from} onChange={onChange} />*/}
          <InputWithDate
            name="from"
            label="Дата от"
            value={from}
            onChange={onChange}
            maxDate={new Date()}
          />
        </FormCol>
        <FormCol>
          {/*<BaseInput name="to" label="Дата до" type="date" value={to} onChange={onChange} />*/}
          <InputWithDate
            name="to"
            label="Дата до"
            value={to}
            onChange={onChange}
            maxDate={new Date()}
          />
        </FormCol>
        <FormCol xs="auto">
          <MtsButton disabled={isApply} loading={isLoadingGetList} onClick={onClickApply}>
            Применить
          </MtsButton>
        </FormCol>
        <FormCol xs="auto">
          <Tooltip label="Скачать отчёт">
            <MtsButton
              format={'icon' as ButtonFormats}
              disabled={isDownload}
              loading={isLoadingExportList}
              onClick={onClickExport}
            >
              <Icon name="download" />
            </MtsButton>
          </Tooltip>
        </FormCol>
        <FormCol xs={12}>
          <AntCheckbox
            name="isCollapseTransitions"
            onChange={onChange}
            checked={isCollapseTransitions}
          >
            Группировать по зонам
          </AntCheckbox>
        </FormCol>
        {isShowReset && (
          <FormCol xs={12}>
            <Button color={'ghost' as ButtonColors} onClick={onClickReset}>
              Сбросить фильтры
            </Button>
          </FormCol>
        )}
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
