/**
 * Переименует массив подразделений
 */
import { IDepartments } from '../models/types';

interface IRenamedObject {
  value: string;
  title: string;
  children?: IRenamedObject[];
}

const renameDepartmentsProperties = (objects: IDepartments[]): IRenamedObject[] => {
  return objects.map(obj => {
    const { id, name, children } = obj;
    return {
      value: id,
      title: name,
      children: children ? renameDepartmentsProperties(children) : undefined
    };
  });
};

export default renameDepartmentsProperties;
