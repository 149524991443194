import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMtsModal } from '@mts-ds/components-react';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../Root/paths';
import { IEmployeesList, IUserEmployees } from '../../../../models/types';
import { useAsync, useRequestHandler, useParams } from '../../../../hooks';
import { IGetAllParams } from '../../../../services/UsersService';

import Spinner from '../../../../components_/Spinner';
import Pagination from '../../../../components_/Pagination';
import EmptyState from '../../../../components_/EmptyState';
import Modal from '../../../../components_/Modal';

import Filter from './Filter';
import { Table, Checkbox, Button, Popover, Col, Row, Space } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import Icon from '../../../../components_/Icon';
import Link from 'antd/lib/typography/Link';

const initialFilter: Omit<IGetAllParams, 'pageIndex'> = {
  fullTextSearch: '',
  departmentId: undefined
};

const mapState = (state: iRootState) => ({
  list: state.staffUsers.list,
  data: state.staffUsers.data
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getList: d.staffUsers.getList,
    getData: d.staffUsers.getData,
    remove: d.staffUsers.remove,
    reset: d.staffUsers.reset,
    exportList: d.staffUsers.exportList
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({
  list: { data: list, pagesCount },
  data,
  getData,
  getList,
  remove,
  reset,
  exportList
}) => {
  const [active, setActive] = useState<IUserEmployees | null>(null);

  const history = useHistory();
  const requestHandler = useRequestHandler();

  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData);
  const [runGetList, isLoadingGetList, isGetListLoadingError] = useAsync(getList);
  const [runExportList, isLoadingExportList, isExportListLoadingError] = useAsync(exportList);
  const { pageIndex, filter, handlePageChange, handleFilterChange, handleFilterReset } = useParams(
    initialFilter,
    runGetList
  );

  const updateList = () => {
    getList({ ...filter, pageIndex });
  };

  const [openDeleteModal] = useMtsModal(Modal, {
    header: 'Удалить сотрудника',
    text: 'Вы действительно хотите удалить сотрудника?',
    buttonTextConfirm: 'Удалить',
    ...(active && {
      onMtsModalConfirm: async () => {
        const error = await remove(active.id);

        requestHandler({
          error,
          entity: 'user',
          isDelete: true,
          onSuccess: updateList
        });
      }
    })
  });

  useEffect(() => {
    runGetData();
    return () => {
      reset();
    };
  }, []);

  const handleClickCreate = () => {
    history.push(paths.staff.users.create);
  };

  const handleClickEdit = ({ id }: IUserEmployees) => () => {
    history.push(paths.staff.users.edit(id));
  };

  const handleClickDelete = (user: IUserEmployees) => () => {
    setActive(user);
    openDeleteModal();
  };

  const handleExportList = () => {
    runExportList(filter);
  };

  const isLoading = isLoadingGetList || isLoadingGetData;
  const isLoadingError = isGetListLoadingError || isGetDataLoadingError;

  const content = () => (
    <Checkbox.Group
      value={checkedList}
      style={{ width: '180px' }}
      // options={options as CheckboxOptionType[]}
      onChange={value => {
        setCheckedList(value as string[]);
      }}
    >
      <Row>
        {options.map(option => (
          <Col key={option.value} span={24}>
            <Checkbox key={option.value} value={option.value}>
              {option.label}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
      // filterSearch: true,
      // sorter: {
      //   // @ts-ignore
      //   compare: (a, b) => a.fullName.localeCompare(b.fullName),
      //   multiple: 1
      // },
      // defaultSortOrder: 'ascend' as const,
      render: (_: any, record: any) => (
        <Link onClick={handleClickEdit(record)}>{record.fullName}</Link>
      )
    },
    {
      title: 'Должность',
      dataIndex: 'post',
      key: 'post',
      render: (_: any, record: IEmployeesList) => (
        <div>{record.post && record.post.name ? record.post.name : '-'}</div>
      )
      // sorter: {
      //   // @ts-ignore
      //   compare: (a, b) =>
      //     a.post && a.post.name
      //       ? a.post.name
      //       : ''.localeCompare(b.post && b.post.name ? b.post.name : ''),
      //   multiple: 1
      // },
      // // filters: getUniquePosts(list),
      // onFilter: (value: any, record: any) =>
      //   record.post && record.post.id && record.post.id.startsWith(value as string),
      // filterSearch: true
    },
    {
      title: 'Подразделение',
      dataIndex: 'department',
      key: 'department',
      render: (_: any, record: IEmployeesList) => (
        <div>
          {record.department && record.department.rootName
            ? record.department.rootName +
              (record.department.name ? ' / ' + record.department.name : '-')
            : record.department && record.department.name
            ? record.department.name
            : '-'}
        </div>
      ),
      ellipsis: true
    },
    {
      title: 'ID устройства',
      dataIndex: 'deviceId',
      key: 'deviceId',
      render: (_: any, record: IEmployeesList) => (
        <div>{record.deviceId ? record.deviceId : '-'}</div>
      )
    },
    {
      title: 'Сигнал получен',
      dataIndex: 'lastPositionTime',
      key: 'lastPositionTime',
      render: (_: any, record: IEmployeesList) => (
        <div>{record.lastPositionTime ? record.lastPositionTime : '-'}</div>
      )
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //       <>
    //         {tags.map((tag) => {
    //           let color = tag.length > 5 ? 'geekblue' : 'green';
    //           if (tag === 'loser') {
    //             color = 'volcano';
    //           }
    //           return (
    //               <Tag color={color} key={tag}>
    //                 {tag.toUpperCase()}
    //               </Tag>
    //           );
    //         })}
    //       </>
    //   ),
    // },
    {
      title: (
        <Popover content={content} placement="bottom" trigger="click">
          <Button shape="circle" icon={<SettingOutlined />} />
          {/*<Button><Icon name="checkMark" size="s" /></Button>*/}
        </Popover>
      ),
      key: 'action',
      align: 'right' as 'right',
      width: '10%',
      render: (_: any, record: any) => (
        <Space>
          <Button onClick={handleClickEdit(record)} icon={<Icon name={'edit'} size="m" />} />
          <Button onClick={handleClickDelete(record)} icon={<Icon name={'bin'} size="m" />} />
        </Space>
      )
    }
  ];

  const defaultCheckedList = columns.map(item => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columns
    .filter(({ key }) => key !== 'action') // исключаем "action" из чекбоксов
    .map(({ key, title }) => ({
      label: title,
      value: key
    }));

  const newColumns = columns.map(item => ({
    ...item,
    hidden: item.key !== 'action' && !checkedList.includes(item.key as string) // "action" всегда отображается
  }));

  return (
    <>
      {!isLoadingGetData && (
        <>
          <Filter
            filter={filter}
            list={list}
            data={data}
            isLoadingExportList={isLoadingExportList}
            onChange={handleFilterChange}
            onClickCreate={handleClickCreate}
            onClickExport={handleExportList}
            onReset={handleFilterReset}
          />
          {!isLoadingGetList && !isGetListLoadingError && (
            <>
              {!list && <EmptyState variant="generateReport" />}
              {list && (
                <>
                  {!Boolean(list.length) && <EmptyState variant="emptyRequest" />}
                  {Boolean(list.length) && (
                    <>
                      <Table
                        style={{ marginTop: '1em', overflow: 'auto' }}
                        virtual
                        size="small"
                        rowKey="id"
                        scroll={{ x: 1000, y: 600 }}
                        pagination={false}
                        columns={newColumns}
                        dataSource={list}
                        tableLayout={'auto'}
                      />
                      {/*<List*/}
                      {/*  list={list}*/}
                      {/*  onClickEdit={handleClickEdit}*/}
                      {/*  onClickDelete={handleClickDelete}*/}
                      {/*/>*/}
                      <Pagination
                        pageIndex={pageIndex}
                        pagesCount={pagesCount}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
