import React, { FC } from 'react';
import { InputProps } from 'antd/es/input';

import { Input as AntInput } from 'antd';

import FormGroup, { IPropsText as IFormGroupTextProps } from '../components/FormGroup';
interface IInputProps {
  name: string;
  valid?: boolean;
  onChange?: any;
  value?: any;
}

export type IProps = IFormGroupTextProps & IInputProps & InputProps;

const Input: FC<IProps> = ({ label, tooltip, description, valid, ...props }) => {
  return (
    <FormGroup label={label} description={description} tooltip={tooltip} valid={valid}>
      <AntInput {...props} />
    </FormGroup>
  );
};

export default Input;
