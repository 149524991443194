import React, {FC, useEffect} from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FeatureGroup } from 'react-leaflet';

import { Dispatch, iRootState } from '../../../store';
import { paths } from '../../Root/paths';


import EditControl from './EditControl';
import AreaListItem from './AreaListItem';
import BeaconMarkerListItem from './BeaconMarkerListItem';
import CameraMarkerListItem from './CameraMarkerListItem';
import OfficeMarkerListItem from './OfficeMarkerListItem';

const mapState = (state: iRootState) => ({
  map: state.infrastructureMap.map,
  mode: state.infrastructureMap.mode,
  type: state.infrastructureMap.type,
  office: state.infrastructureOffices.item,
  cameraEdit: state.infrastructureCameras.cameraEdit,
  beaconItem: state.infrastructureBeacons.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    setAreaCoordinates: d.infrastructureAreas.setCoordinates,
    setBeaconCoordinates: d.infrastructureBeacons.setCoordinates,
    setOfficeCoordinates: d.infrastructureOffices.setCoordinates,
    setCameraCoordinates: d.infrastructureCameras.setCoordinates,
    setMarkerEditMode: d.infrastructureMap.setMarkerEditMode,
    setBoxEditMode: d.infrastructureMap.setBoxEditMode,
    setCircleEditMode: d.infrastructureMap.setCircleEditMode,
    setPolygonEditMode: d.infrastructureMap.setPolygonEditMode,
    setItemEdit: d.infrastructureCameras.setItemEdit,
    setDefaultMode: d.infrastructureMap.setDefaultMode
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type IProps = connectedProps;

interface IAreaParams {
  id: string;
}

interface IBeaconParams {
  mac: string;
}

interface IOfficeParams {
  id: string;
}

interface ICameraParams {
  id: string;
}

const Edit: FC<IProps> = ({
  map,
  mode,
  type,
  setAreaCoordinates,
  setBeaconCoordinates,
  setOfficeCoordinates,
  setCameraCoordinates,
  setMarkerEditMode,
  setBoxEditMode,
  setCircleEditMode,
  setPolygonEditMode,
  office,
  cameraEdit,
  beaconItem,
  setItemEdit,
  setDefaultMode
}) => {
  const { areas, beacons, cameras } = map;

  const areaEditMatch = useRouteMatch<IAreaParams>(paths.map.office.areas.edit());
  const beaconEditMatch = useRouteMatch<IBeaconParams>(paths.map.office.infrastructure.edit());
  const officeEditMatch = useRouteMatch<IOfficeParams>(paths.map.office.edit());
  const cameraEditMatch = useRouteMatch<ICameraParams>(paths.map.office.cameras.edit());

  const areaParamId = areaEditMatch ? areaEditMatch.params.id : null;
  const areaEdit = areaParamId ? areas.find(item => item.id === areaParamId) : null;
  const beaconParamMac = beaconEditMatch ? beaconEditMatch.params.mac : null;
  const beaconEdit = beaconParamMac
    ? beacons.find(item => item.macAddress === beaconParamMac)
    : null;
  // setBeaconEdit(beaconItem);
  // useEffect(() => {
  //   const cameraParamId = cameraEditMatch ? cameraEditMatch.params.id : null;
  //   const cameraItem = cameraParamId ? cameras.find(item => item.id === cameraParamId) : null;
  //   setItemEdit(cameraItem);
  // }, []);
  const cameraParamId = cameraEditMatch ? cameraEditMatch.params.id : null;
  const cameraItem = cameraParamId ? cameras.find(item => item.id === cameraParamId) : null;
  useEffect(() => {
    setItemEdit(cameraItem);
    // tslint:disable-next-line:prettier
  }, [cameraItem?.location]);
  // setItemEdit(cameraItem);

  const officeParamId = officeEditMatch ? officeEditMatch.params.id : null;
  const officeEdit = office && office.id === officeParamId;
  // // // todo переделать логику под офисы

  return (
    <FeatureGroup>
      {/*{mode !== AreaModes.Default && (*/}
        <EditControl
          setAreaCoordinates={setAreaCoordinates}
          setBeaconCoordinates={setBeaconCoordinates}
          setOfficeCoordinates={setOfficeCoordinates}
          setCameraCoordinates={setCameraCoordinates}
          setMarkerEditMode={setMarkerEditMode}
          setBoxEditMode={setBoxEditMode}
          setCircleEditMode={setCircleEditMode}
          setPolygonEditMode={setPolygonEditMode}
          mode={mode}
          type={type}
        />
      {/*)}*/}
      {areaEdit && <AreaListItem area={areaEdit} />}
      <BeaconMarkerListItem item={beaconItem} edit={!!beaconEditMatch} />
      <CameraMarkerListItem item={cameraEdit} edit={!!cameraEditMatch} />
      {officeParamId && <OfficeMarkerListItem key={office?.id} office={office} index={1} edit={!!officeEditMatch} />}
    </FeatureGroup>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Edit);
