import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';

import { Dispatch, iRootState } from '../../../../store';
import { paths } from '../../../Root/paths';
import { useAsync } from '../../../../hooks';

import Content from '../../../../components_/Content';
import Spinner from '../../../../components_/Spinner';
import EmptyState from '../../../../components_/EmptyState';

import Form from './Form';

interface IParams {
  id: string;
}

const mapState = (state: iRootState) => ({
  data: state.staffUsers.data,
  item: state.staffUsers.item
});

const mapDispatch = (dispatch: any) => {
  const d = dispatch as Dispatch;

  return {
    getData: d.staffUsers.getData,
    getDataAreas: d.staffUsers.getDataAreas,
    getItem: d.staffUsers.getItem,
    updateOrCreate: d.staffUsers.updateOrCreate
  };
};

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export type IProps = connectedProps;

const Index: FC<IProps> = ({ data, item, getData, getDataAreas, getItem, updateOrCreate }) => {
  const [runGetData, isLoadingGetData, isGetDataLoadingError] = useAsync(getData, true);
  const [runGetItem, isLoadingGetItem, isGetItemLoadingError] = useAsync(getItem);
  const { id } = useParams<IParams>();
  const history = useHistory();
  const isCreate = Boolean(useRouteMatch(paths.staff.users.create));
  const isEdit = Boolean(useRouteMatch(paths.staff.users.edit()));

  useEffect(() => {
    runGetData();

    if (isEdit) {
      runGetItem(id);
    }
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const isLoading = isLoadingGetData || isLoadingGetItem;
  const isLoadingError = isGetDataLoadingError || isGetItemLoadingError;

  const title = isCreate
    ? 'Добавить сотрудника'
    : isEdit && item
    ? `${item.lastName && item.lastName} ${item.name && item.name} ${item.secondName &&
        item.secondName}`
    : '';

  return (
    <Content
      breadcrumb={[{ name: 'Персонал', url: paths.staff.root }, { name: title }]}
      title={title}
    >
      {!isLoading && !isLoadingError && (
        <>
          <Form
            data={data}
            item={item}
            isCreate={isCreate}
            isEdit={isEdit}
            getDataAreas={getDataAreas}
            onSubmitAction={updateOrCreate}
            onCancel={handleCancel}
          />
        </>
      )}
      {isLoading && <Spinner />}
      {isLoadingError && <EmptyState variant="serverError" />}
    </Content>
  );
};

const withConnect = connect(
  mapState,
  mapDispatch
);

export default withConnect(Index);
